#notes-wrapper #title{
    text-align: center;
    text-decoration: underline;
    font-size: 18px;
}

#notes-wrapper #text li{
    padding-bottom: 10px;
    font-size: 10px;
}

#notes-wrapper #text .highlight{
    font-weight: 900;
}