#footer-wrapper #footer-1{
    display: flex;
    width: 75%;
    margin: auto;
    flex-direction: column;
    font-size: 15px;
}

#footer-wrapper #footer-2{
    display: flex;
    flex-direction: row;
    padding: 20px;
    justify-content: space-between;
    font-size: 15px;
}

#footer-wrapper span.valueUL{
    /* text-decoration: underline; */
    border-bottom: 1px solid black;
    text-align: center;
}
#footer-wrapper span.value{
    text-align: center;
}