.container {
    margin: auto;
}

@media print {
    @page {
        size: A4 portrait;
        max-height: 100%;
        max-width: 90%
    }
}