:is(#form-data-wrapper){
    padding:15px;
    padding-bottom: 0px;
}

:is(#form-data-wrapper) #text{
    display: flex;
    flex-direction: column;
}

:is(#form-data-wrapper) #text div{
    width: 100%;
    padding-right: 15px;
}

:is(#form-data-wrapper) #text .field{
    font-size: 12px;
    font-weight: bold;
}

:is(#form-data-wrapper)  #text .value{
    font-size: 14px;
    font-style: italic;
    font-weight: bold;
    /* text-decoration: underline; */
    /* border-bottom: 1px solid black; */
}

:is(#form-data-wrapper) #table-wrapper{
    width: 50%;
    margin: auto;
    padding-bottom: 20px;
}

:is(#form-data-wrapper)  #table-wrapper, table {
    border-collapse: collapse;
/*   border:  1px solid black;*/
}

:is(#form-data-wrapper)  #table-wrapper table th{
    background-color: lightgray;
    word-wrap:break-word;
}

:is(#form-data-wrapper) #table-wrapper table td{

    border: 1px solid black;
    padding: 10px;
    text-align: center;
}