#title-wrapper #title h1{
    text-align: center;
    font-style: italic;
    font-weight: 900;
    font-size: 15px;
}

#title-wrapper #subtitle{
    text-align: center;
    font-style: italic;
    font-weight: 900;
    font-size: 12px;
}

#title-wrapper #form-name h1{
    text-align: center;
    font-size: 15px;
    font-weight: 800;
    margin: 0;
}